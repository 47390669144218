<template>
  <div class="content-wrapper">
    <div class="placeholder">
      <div class="animated-background" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentPlaceholder'
}
</script>

<style lang="sass" scoped>
.placeholder
 margin: 0 auto
 max-width: 100%
 min-height: 0.5rem
 background-color: #eee
 border-radius: 1rem

@keyframes placeHolderShimmer
  0%
    background-position: -468px 0
  100%
    background-position: 468px 0

.animated-background
  border-radius: 1rem
  animation-duration: 1s
  animation-fill-mode: forwards
  animation-iteration-count: infinite
  animation-name: placeHolderShimmer
  animation-timing-function: linear
  background: darkgray
  background: linear-gradient(to right, #f1f1f1 10%, #e1e1e1 18%, #f1f1f1 33%)
  background-size: 800px 104px
  height: 0.5rem
  position: relative
</style>
